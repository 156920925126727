import { useState } from "react";
import icon from "../assets/icon.png";
import { Title, Subtitle, Button, List } from "../components";
import { ButtonDisabled } from "./ButtonDisabled";

export const Banner = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="bg-gradient-to-r from-[#047fdd] via-[#4b4f82] to-[#9c1919] text-white mx-5 md:mx-10 rounded-xl py-2 md:py-5">
      <div className="flex space-x-1 items-center">
        <div className="flex flex-col w-1/5 text-center items-center">
          <p className="font-[700] text-[8px] md:text-lg lg:text-2xl md:tracking-wide">
            SBU World Cup
          </p>
          <a href="https://socialbees.io" target="_blank" className="flex items-center justify-center"><img src={icon} alt="" className="w-1/3 h-auto" /></a>
        </div>
        <div className="flex flex-col w-1/5 text-center items-center border-r">
          <Subtitle text="Potential Game Vault" />
          <Title text="325 ETH" />
          {/*<span className="text-[8px]">*Amount includes fee transactions</span>*/}
          {/*<Subtitle text="Current: ETH" />*/}
        </div>
        {/*<div className="flex flex-col w-1/5 text-center items-center border-r">
          <Title text="212" />
          <Subtitle text="Players" />
        </div>*/}
        <div className="flex flex-col w-1/5 text-center items-center border-r">
          <Subtitle text="Entry Begins" />
          <Title text="Nov 20th" />
        </div>
        <div className="flex flex-col w-1/5 text-center items-center">
          <Subtitle text="Entry Ends" />
          <Title text="Dec 20th" />
        </div>
        <div className="flex flex-col w-1/5 text-center items-center">
          <a href="https://sbu.traitwar.xyz/" target="_blank">
            <Button text="Join Match"/>
          </a>
        </div>
      </div>
      <div className="flex flex-row items-center justify-end font-[700] text-xs md:text:lg lg:text-xl mx-0 md:mx-20">
        How to play?
        <span
          className="mx-2 cursor-pointer bg-white rounded-full text-black p-1"
          onClick={handleClick}
        >
          {isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-2 h-2 md:w-4 md:h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 15.75l7.5-7.5 7.5 7.5"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-2 h-2 md:w-4 md:h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          )}
        </span>
      </div>
      {isOpen && (
        <div className="bg-[#030202] mx-2 md:mx-10 lg:mx-14 my-5 rounded-xl py-10">
          <List />
        </div>
      )}
    </div>
  );
};
