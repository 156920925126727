export const ListItem = ({ number, title, text }) => {
  return (
    <div className="ml-0 md:ml-5 lg:ml-20">
      <p className="first-letter:text-3xl second-letter:text-3xl md:first-letter:text-4xl lg:first-letter:text-6xl first-letter:font-bold text-[#F3A43A] first-letter:mr-3 first-letter:float-left mb-5 md:mb-10 lg:mb-16 font-[700] text-normal md:text-lg lg:text-2xl">
        {number} {title}
        <p className="text-white text-justify text-clip overflow-hidden font-normal text-sm md:text-normal lg:text-xl">
          {text}
        </p>
      </p>
    </div>
  );
};
