import { ListItem } from "../components";

export const List = () => {
  return (
    <div className="md:w-1/2 mx-5 md:mx-40 text-white">
      <p className="text-lg md:text-[20px] lg:text-[40px] text-white font-[700] tracking-wide pb-2 mb-10 md:mb-20">
        How to play in the SBU World Cup?
      </p>
      <ListItem
        number="1"
        title="Must own a qualified BEE NFT"
        text={<p>Must have a BEE NFT from the official Social BEES University collection AND have 1 of the 2 traits:<br/>
        <ul className="list-disc list-inside indent-10">
          <li>Soccer (Necklace)</li>
          <li>World Cup (Base)</li>
        </ul><br/>
        
        Players can find qualified BEE NFTs on Opensea by applying the proper trait filters.<br/> 
        <a href="https://opensea.io/collection/social-bees-university" className="text-[#697ddb]">https://opensea.io/collection/social-bees-university</a><br/><br/>
        
        For help join the BEES discord or visit the website<br/>
        <a href="https://discord.gg/web3global" className="text-[#697ddb]">https://discord.gg/web3global</a><br/>
        <a href="https://socialbees.io" className="text-[#697ddb]">https://socialbees.io</a>
        </p>}
      />
      <ListItem
        number="2"
        title="Select Join Match"
        text="Proceed to connect your Ethereum wallet to the website."
      />
      <ListItem
        number="3"
        title="Select BEE NFT(s) and pick your team(s)"
        text={<p>For each BEE NFT(s) you can select which team you want to join. Teams are competing for game vault rewards. 
        <ul className="list-disc list-inside">
        <li>The team with more goals has a higher probability of winning game rewards, but the rewards will be shared with more people.</li>
        <li>The team with less goals has a lower probability of winning, but the rewards will be shared with fewer people.</li>
        </ul>
        </p>}
      />
      <ListItem
        number="4"
        title="Submit"
        text={<p>After selecting a team, players will now submit their BEE NFT(s) into the match. Each participating NFT(s) adds goal(s) to the match. 
        <ul className="list-disc list-inside indent-10">
          <li>Soccer (Necklace) = 1 goal</li>
          <li>World Cup (Base) = 7 goals</li>
        </ul><br/>
        
        Note: This is a transaction on the ethereum blockchain and will consist of “gas fees”. 
        </p>}
      />
      <ListItem
        number="5"
        title="After submitting"
        text={<p>Every BEE NFT submitted into the match is transferred and locked into a secured vault until the completion of the match. Players will receive a proof of entry as a TraitWar NFT.<br/><br/>

        Submitted BEE NFT(s) and game rewards can <span class="underline decoration-sky-400 text-sky-300">only</span> be claimed from the wallet address holding the matching TraitWar NFT(s). <span class="text-sky-300 font-bold">If you sell the TraitWar NFT(s) all future game winnings and the right to claim back the BEE NFT(s) are passed to the new owner of the TraitWar NFT.</span>
        </p>}
      />
      <ListItem
        number="6"
        title="Follow the match for updates"
        text="Continue supporting your team! You can add more BEE NFT(s) anytime while the tournament is still open. Entry starts November 20, 2022 - Ends December 20, 2022 (UTC Time)
        "
      />
      <ListItem
        number="7"
        title="Goal Milestones"
        text={<p>Every goal scored is added up and aiming to reach milestones. Every milestone reached will add a Queen BEE NFT to the Game Vault.<br/><br/>
        <ul className="list-disc list-inside indent-10">
          <li>Princess Queen of Benin (35 ETH) added to Game Vault at 20 Goal Milestone</li>
          <li>Princess Queen of Kyrgyzstan (35 ETH) added to Game Vault at 40 Goal Milestone</li>
          <li>Princess Queen of Latvia (35 ETH) added to Game Vault at 60 Goal Milestone</li>
          <li>Princess Queen of Pakistan (35 ETH) added to Game Vault at 80 Goal Milestone</li>
          <li>Princess Queen of Swaziland (35 ETH)  added to Game Vault at 100 Goal Milestone</li>
          <li>Princess Queen of Malta (35 ETH) added to Game Vault at 120 Goal Milestone</li>
          <li>Princess Queen of Aruba (35 ETH) added to Game Vault at 140 Goal Milestone</li>
          <li>Princess Queen of Tanzania (35 ETH) added to Game Vault at 160 Goal Milestone</li>
          <li>Princess Queen of Sweden (35 ETH) added to Game Vault at 180 Goal Milestone</li>
          <li>Princess Queen of Argentina (35 ETH) added to Game Vault at 200 Goal Milestone</li>
          <li>Royal Queen of Sierra Leone (100 ETH) added to Game Vault at 250 Goal Milestone</li>
          <li>Royal Queen of Senegal (100 ETH) added to Game Vault at 350 Goal Milestone</li>
          <li>Royal Queen of Argentina (100 ETH) added to Game Vault at 450 Goal Milestone</li> 
        </ul>   
        </p>}
      />
      <ListItem
        number="8"
        title="Game Vault"
        text={<p>The game vault consists of Queen BEE NFT(s) from the Social BEES collection that will be sold on Opensea at a predetermined price. Each time one of these Queens are sold<br/>
        <ul className="list-disc list-inside indent-10">
        <li>50% will be sent to the person that submitted the Queen.</li>
        <li>50% will be distributed to a winning team.</li>
        </ul><br/>
        The winning team depends on probability, the probability is calculated based on the amount of goals from each team.<br/>
        <ul className="list-disc list-inside indent-10">
        <li>The team with more goals has a higher probability of winning game rewards, but the rewards will be shared with more people.</li>
        <li>The team with less goals has a lower probability of winning, but the rewards will be shared with fewer people.</li>
        </ul><br/>
        
        Potential Game Vault Value: 325 ETH         
        </p>}
      />
      <ListItem
        number="9"
        title="Claim rewards"
        text={<p>After the winning team is selected each team member will be able to claim rewards by connecting their wallet to the TraitWar website.<br/><br/>

        Note: Must hold the matching TraitWar NFT(s) in order to claim rewards.        
        </p>}
      />
      <ListItem
        number={<span>1<span className="text-3xl md:text-4xl lg:text-6xl font-bold text-[#F3A43A] mr-3 float-left">0</span></span>}
        title="SBU World Cup Ends"
        text="Once all Queen BEE NFT(s) are sold from the Game Vault, and all game rewards are distributed this will complete the SBU World Cup game. Submitted BEE NFT(s) will then be unlocked and able to be claimed using the correlating TraitWar NFT(s). 
        "
      />
      <ListItem
        number={<span>1<span className="text-3xl md:text-4xl lg:text-6xl font-bold text-[#F3A43A] mr-3 float-left">1</span></span>}
        title="Follow TraitWar on Twitter for future matches and updates!"
        text={<a href="https://twitter.com/traitwar" className="text-[#697ddb]">https://twitter.com/traitwar</a>}
      />
    </div>
  );
};
