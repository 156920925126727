import { Title, Subtitle, Button } from "../components";

export const FooterBanner = () => {
  return (
    <div className="bg-gradient-to-r from-[#724e1d] to-[#f3a43a] text-white mx-5 md:mx-10 rounded-xl py-2 md:py-10">
      <div className="flex space-x-1 items-center justify-between px-2 md:px-5 lg:px-16">
        <div>
          <Title text="Create your own TraitWar!" />
          <Subtitle text="Are your NFT frens ready for a Trait challenge? Contact us by filling out the form!" />
        </div>
        <div>
          <a href="https://forms.gle/jJoFgwfNs1KZDqqa9" target="_blank">
            <Button text="Contact Us" />
          </a>
        </div>
      </div>
    </div>
  );
};
