import { Banner, Script, FooterBanner } from "../components";

export const Content = () => {
  return (
    <main className="pb-10">
      <Banner />
      <Script />
      <FooterBanner />
    </main>
  );
};
