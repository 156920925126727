import { Helmet } from "react-helmet";

export const Script = () => {
  return (
    <div id="script" className="text-white my-10 w-screen">
          <section>
        <embed
          width='100%'
          height='600px'
          background-color= '#030202'
          src='https://my.walls.io/traitwar?show_header=0'
          style={{ margin: "20px 0" }}
        />
      </section>
    </div>
  );
};