import { Content, Header } from "./components";

const App = () => {
  return (
    <div className="bg-[#030202] pb-10">
      <Header />
      <Content />
    </div>
  );
};

export default App;
