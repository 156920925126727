import logo from "../assets/logo.png";
import cards from "../assets/cards.png";
import twitter from "../assets/twitter.png";

export const Header = () => {
  return (
    <div
      className="flex flex-col lg:flex-row bg-cover bg-no-repeat bg-center px-5 md:px-10 lg:px-40 pt-10 pb-10 md:pb-20 lg:pb-40"
      style={{
        backgroundImage: `url("https://i.postimg.cc/hvTrkKJS/bg.png")`,
      }}
    >
      <div className="flex flex-col justify-center items-center px-5">
        <img src={logo} alt="Trait War" className="w-1/2 h-auto pb-5" />
        <p className="text-center text-3xl md:text-[40px] lg:text-[60px] text-white font-[700] tracking-wide leading-normal pb-2">
          Get Your NFT’s Ready!
        </p>
        <p className="text-justify text-[14px] md:text-[20px] lg:text-[25px] text-white font-[400]">
          Enter Trait tournaments and win rewards.
        </p>
        <br/>
      </div>
      <img
        src={cards}
        alt="Trait War"
        className="w-1/2 h-auto mx-auto scale-125"
      />
    </div>
  );
};
